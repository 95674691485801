import { UseQueryResult, useQuery } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';
import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { Summary } from '@hooks/useProjectSummaries';
import { recomputeScores } from './useBenchmarkData';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';
import { ShapDriversDataPoint } from '@components/charts/datamodels/ShapDriversDataPoint';

export function mergeByTopicForOverTime(
  array: BenchmarkDataPoint[]
): { firstPop: BenchmarkDataPoint; secondPop: BenchmarkDataPoint }[] {
  const grouped = array.reduce((acc, obj) => {
    if (!acc[obj.topic]) {
      acc[obj.topic] = [];
    }
    acc[obj.topic].push(obj);
    return acc;
  }, {});

  const mergedArray = [];

  for (const key in grouped) {
    const group = grouped[key];
    for (let i = 0; i < group.length; i += 2) {
      const first = group[i];
      const second = group[i + 1];
      mergedArray.push({
        firstPop: first,
        secondPop: second,
      });
    }
  }

  return mergedArray;
}

const parseBenchmarkData = (
  data: BenchmarkDataPoint[],
  focalPopulation: string
) => {
  let obj = {};
  data.forEach((dp) => {
    if (dp.populationName === focalPopulation)
      obj[dp.topic.toLowerCase()] = dp.benchmarkScore;
  });
  return obj;
};

const parseRawVoiceData = (
  data: BenchmarkDataPoint[],
  focalPopulation: string
) => {
  // Helper function to calculate mean
  const mean = (values: number[]): number =>
    values.reduce((acc, val) => acc + val, 0) / values.length;

  // Helper function to calculate standard deviation
  const stdDev = (values: number[]): number => {
    const mu = mean(values);
    return Math.sqrt(
      values.reduce((acc, val) => acc + Math.pow(val - mu, 2), 0) /
        values.length
    );
  };

  // Function to calculate and update z-scores for each group
  const values = data.map(
    (d) => d.positiveIncidence - d.negativeIncidence ?? 0
  );
  const mu = mean(values);
  const sigma = stdDev(values);

  data.forEach((group) => {
    group[`positiveIncidenceMinusNegativeIncidence`] =
      ((group.positiveIncidence - group.negativeIncidence ?? 0) - mu) / sigma;
  });

  let obj = {};
  data.forEach((dp) => {
    if (dp.populationName === focalPopulation) dp.rowCount;
    obj[dp.topic.toLowerCase()] =
      dp['positiveIncidenceMinusNegativeIncidence'] ?? 0;
  });

  return obj;
};

const parseDrivenData = (
  data: ShapDriversDataPoint[],
  focalPopulation: string
) => {
  // Helper function to calculate mean
  const mean = (values: number[]): number =>
    values.reduce((acc, val) => acc + val, 0) / values.length;

  // Helper function to calculate standard deviation
  const stdDev = (values: number[]): number => {
    const mu = mean(values);
    return Math.sqrt(
      values.reduce((acc, val) => acc + Math.pow(val - mu, 2), 0) /
        values.length
    );
  };

  // Function to calculate and update z-scores for each group
  const values = data.map((d) => d['combinedPercentTotalShap'] ?? 0);
  const mu = mean(values);
  const sigma = stdDev(values);

  data.forEach((group) => {
    group[`combinedPercentTotalShapZ`] =
      (((group['combinedPercentTotalShap'] ?? 0) - mu) / sigma) * -1;
  });

  let obj = {};
  data.forEach((dp) => {
    if (dp.population === focalPopulation)
      obj[dp.feature.toLowerCase()] = dp['combinedPercentTotalShapZ'] ?? 0;
  });
  return obj;
};

const parseOverTimeData = (data: BenchmarkDataPoint[], pops: string[]) => {
  let mergedData:any = mergeByTopicForOverTime(data);

  let obj = {};

  for (let index = 0; index < mergedData.length; index++) {
    const firstPopulationElement = mergedData[index].firstPop;
    const secondPopulationElement = mergedData[index].secondPop;
    let theCalculation = 0;
    if (!firstPopulationElement || !secondPopulationElement) {
      continue;
    }
    theCalculation =
      (10 / 3) *
      ((firstPopulationElement.positiveIncidence -
        secondPopulationElement.positiveIncidence -
        (firstPopulationElement.negativeIncidence -
          secondPopulationElement.negativeIncidence)) /
        ((firstPopulationElement.incidence +
          secondPopulationElement.incidence) /
          2));
    // }
    mergedData[index].theCalculation = theCalculation
    obj[firstPopulationElement.topic.toLowerCase()] = theCalculation;
  }
  return obj;
};

export type SumaryData = {
  topic: string;
  prioritize: number;
} & Partial<{
  [analysisId: string]: unknown;
}>;

export const generateCombinedPercentTotalShap = (
  data: ShapDriversDataPoint[]
): ShapDriversDataPoint[] => {
  const totalCombinedShap = data
    .map((d) => d.combinedShap)
    .reduce((p, c) => p + c);
  return data.map(
    (d) =>
      ({
        ...d,
        combinedPercentTotalShap: d.combinedShap / totalCombinedShap,
      } as ShapDriversDataPoint)
  );
};

export const useSummaryData = (
  summary?: Summary
): UseQueryResult<SumaryData[]> => {
  if (summary && summary?.analyses) {
    summary.analyses = summary.analyses.filter((a) => !!a);
  }
  return useQuery({
    queryKey: ['summary-data', ...(summary?.analyses ?? []).map((a) => a.id)],
    queryFn: () => {
      const promises = summary.analyses.map((analysis) => {
        if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
          return Promise.all([
            ProjectAPI.getAnalysisData(
              summary.projectId,
              analysis.id,
              'Topic',
              summary.organizationId
            ),
            ProjectAPI.getAnalysisData(
              summary.projectId,
              analysis.id,
              'Theme',
              summary.organizationId
            ),
          ]).then(([topicData, themeData]) => ({
            analysis,
            data: {
              ...parseDrivenData(topicData, analysis.focalPopulation),
              ...parseDrivenData(
                generateCombinedPercentTotalShap(themeData),
                analysis.focalPopulation
              ),
            },
            rawData: [],
          }));
        } else if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
          return ProjectAPI.getBenchmarkData(
            summary.projectId,
            analysis.benchmark,
            '*',
            '*'
          )
            .then(recomputeScores)
            .then((data) => ({
              analysis,
              data: parseBenchmarkData(data, analysis.focalPopulation),
              rawData: data,
            }));
        } else if (analysis.type === AnalysisType.RAW_VOICE) {
          return ProjectAPI.getBenchmarkData(
            summary.projectId,
            analysis.benchmark,
            '*',
            '*'
          )
            .then(recomputeScores)
            .then((data) => {
              return {
                analysis,
                data: parseRawVoiceData(data, analysis.focalPopulation),
                rawData: data,
              }
            });
        } else if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {
          return ProjectAPI.getBenchmarkData(
            summary.projectId,
            analysis.benchmark,
            '*',
            '*'
          )
            .then(recomputeScores)
            .then((data) => {
              return {
                analysis,
                data: parseOverTimeData(data, analysis.populations),
                rawData: data,
              };
            });
          // ).then(recomputeScores).then(data => ({ analysis, data: parseRawVoiceData(data, analysis.focalPopulation)}));
        }
      });

      return Promise.all(promises)
        .then((analyses) => {
          let _prioritize = {};
          analyses.forEach(({ data }) => {
            Object.keys(data).forEach((k) => {
              if (!isNaN(data[k])) {
                if (_prioritize[k] === undefined) {
                  _prioritize[k] = data[k];
                } else {
                  _prioritize[k] += data[k];
                }
              }
            });
          });
         
          return Object.keys(_prioritize)
            .map((topic, index) => {
              let obj: SumaryData = {
                topic,
                prioritize: _prioritize[topic] / analyses.length,
              };
              analyses.forEach((a) => {
                obj[a.analysis.id] = {
                  name: a.analysis.name,
                  value: a.data[topic],
                };
              });
              return obj;
            })
            .sort((a, b) => a.prioritize - b.prioritize);
        })
        .catch((e) => {
          console.log('Data');
          console.log(e);
        });
    },
    staleTime: 1000 * 60 * 90, // 90 minutes...
    enabled: !!summary,
  });
};
