import { FC, useCallback, useEffect, useState } from 'react';
import SectionWrapper from '../components/SectionWrapper';
import {
  ActionIcon,
  Flex,
  Group,
  Skeleton,
  Stack,
  Tooltip,
  Transition,
} from '@mantine/core';
import { IconCheck, IconEdit, IconTrash } from '@tabler/icons-react';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useNavigate } from '@hooks/useNavigate';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { notifications } from '@mantine/notifications';
import { DeleteSummaryModal } from '@components/DeleteSummaryModal';
import { SummaryToolbar } from '@components/SummaryToolbar';
import { useSummaryStore } from '@stores/SummaryStore';
import { useProjectTopics } from '@apis/hooks/useProjectTopics';
import { SummaryChart } from '@components/SummaryChart';
import { TopicPanel } from '@components/TopicPanel';
import { useTopicStore } from '@stores/TopicStore';
import { useAppStore } from '@stores/AppStore';
import { IconDownload } from '@tabler/icons';
import SummaryDownloadButton from '@components/SummaryDownloadButton';

export const SummaryPage: FC = () => {
  const currentProject = useCurrentProject()
  const currentSummary = useCurrentSummary()
  const navigate = useNavigate();

  const [showDeleteSummaryModal, setShowDeleteSummaryModal] = useState(false);
  const { data: topicInformation, isLoading: isTopicInformationLoading } =
    useProjectTopics(currentProject);
  const setAllTopics = useTopicStore((s) => s.setAllTopics);
  const setAllThemes = useTopicStore((s) => s.setAllThemes);
  const setTopicDefinitions = useTopicStore((s) => s.setTopicDefinitions);
  const setActiveTopics = useSummaryStore((s) => s.setActiveTopics);
  const setActiveThemes = useSummaryStore((s) => s.setActiveThemes);
  const showTopicPanel = useAppStore((s) => s.showTopicPanel);

  useEffect(() => {
    if (topicInformation) {
      const { topics, themes, topicDefinitions } = topicInformation;
      setAllTopics(topics);
      setAllThemes(themes);
      setTopicDefinitions(topicDefinitions);
      setActiveTopics(topics);
      setActiveThemes(themes);
    }
  }, [topicInformation]);

  const onSummaryDeleted = useCallback(() => {
    navigate(`/projects/${currentProject.projectId}`)
    setShowDeleteSummaryModal(false);
    notifications.show({
      id: 'summary-deleted',
      color: 'green',
      title: 'Plan of Action deleted',
      message: `Plan of Action ${currentSummary.name} was deleted`,
      icon: <IconCheck size="1rem" />,
      autoClose: 15000,
    });
  }, [currentProject?.projectId, currentSummary]);

  if (!currentProject) {
    return (
      <SectionWrapper isFullHeight>
        <Stack spacing={0}>
          <Flex justify="space-between">
            <Skeleton mt="md" height={20} width={100} radius="xl" />
            <Group spacing="xs">
              <Skeleton mt="md" height={20} width={100} radius="xl" />
            </Group>
          </Flex>
        </Stack>
        <Skeleton mt="md" height={20} radius="xl" />
        <Skeleton mt="md" height={400} radius="md" />
      </SectionWrapper>
    );
  }

  return (
    <>
      <DeleteSummaryModal
        project={currentProject}
        summary={currentSummary}
        opened={showDeleteSummaryModal}
        onClose={() => setShowDeleteSummaryModal(false)}
        onDeleted={onSummaryDeleted}
      />
      <SectionWrapper isFullHeight showBreadcrumbs
        rightComponent={
          <Group spacing="xs">
            <Tooltip label="Edit summary" openDelay={500}>
              <ActionIcon onClick={() => navigate(`/projects/${currentProject.projectId}/summaries/${currentSummary.id}/edit`)}>
                <IconEdit size={18} />
              </ActionIcon>
            </Tooltip>
            <SummaryDownloadButton/>
            <Tooltip label="Delete summary" openDelay={500}>
              <ActionIcon onClick={() => setShowDeleteSummaryModal(true)}>
                <IconTrash color="red" size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        }
      >
        <SummaryToolbar />

        <Flex style={{width: "100%", height: "100%"}}>
          <div style={{width: showTopicPanel ? "calc(100% - 450px)" : "100%", height: "100%" , transition: "width ease 0.4s", transitionDelay: showTopicPanel ? "0s" : "0.4s"}}>
            <SummaryChart />
          </div>
          
          <div style={{width: showTopicPanel ? 450 : 0, height: "100%" , transition: "width ease 0.4s", transitionDelay: showTopicPanel ? "0s" : "0.4s"}}>
            <Transition
              mounted={showTopicPanel}
              transition="fade"
              duration={400}
              timingFunction="ease"
            >
              {(styles) => <TopicPanel style={{...styles, transitionDelay: !showTopicPanel ? "0s" : "0.4s"}} showCoMentionTopic={false}/>}
            </Transition>
          </div>
        </Flex>  
      </SectionWrapper>
    </>
  );
};
